import React, { useCallback } from 'react';
import './index.less';
import { ArrowRight } from '@/components/Common/Icons';
import { useRouter } from 'next/router';
import ResponsiveContent from '../ResponsiveContent';

const HomeBanner = () => {
  const router = useRouter();

  const goToBlog = useCallback(() => {
    router.push('/blog/product/pietra-2-0');
  }, [router]);

  return (
    <div className="deal-banner" onClick={goToBlog}>
      <div className="text-container">
        <ResponsiveContent
          set={[
            [null, 720],
            [
              <div className="black-friday-pill" key="deal-banner-text-container-pietra-2">
                <div className="text">Pietra 2.0</div>
              </div>,
            ],
          ]}
        />
        <div className="text">Get highlights from Pietra Panorama 2024</div>
      </div>
      <div className="cta">
        <ArrowRight className="right-icon" />
      </div>
    </div>
  );
};

export default HomeBanner;
