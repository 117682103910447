import React, { useEffect } from 'react';
import { EventSource } from '@/shared/constants/event';
import { Layout } from '@/components/Common';
import { SEO_META } from '@/shared/seo';
import { SEO_META_PAGES } from '@/shared/types/seo';
import { logCommonView, logPageView } from '@/lib/service';
import HomepageV3 from '@/components/Home/HomepageV3';

const Home = () => {
  useEffect(() => {
    logPageView({
      pageUrl: window.location.href || '',
      routeName: window.location.pathname,
      pageTitle: 'Pietra',
    });
    logCommonView(EventSource.HOMEPAGE, 'INDEX');
  }, []);

  return <HomepageV3 />;
};

Home.getLayout = (page: React.ReactElement) => {
  return <Layout structured={SEO_META[SEO_META_PAGES.HOME]?.jsonld()}>{page}</Layout>;
};

export default Home;
