/* eslint-disable @next/next/no-sync-scripts */
import React from 'react';
import classNames from 'classnames';
import { Modal } from 'antd';
import { Vimeo } from '@/shared/types/homepage';
import './index.less';

interface IProps {
  vimeo: Vimeo;
  open: boolean;
  onCancel: () => void;
}

const VimeoLightbox = ({ vimeo, open, onCancel }: IProps) => {
  return (
    <Modal
      width={null}
      open={open}
      destroyOnClose
      wrapClassName={classNames('vimeo-lightbox')}
      onCancel={onCancel}
      footer={false}
      centered
    >
      <div style={{ padding: vimeo.padding }}>
        <iframe
          src={`https://player.vimeo.com/video/${vimeo.id}?badge=0&amp;autopause=0&amp;player_id=0&amp;app_id=58479`}
          frameBorder="0"
          allow="autoplay; fullscreen; picture-in-picture"
          title="Pietra Marketing Tools"
        />
      </div>
      <script src="https://player.vimeo.com/api/player.js"></script>
    </Modal>
  );
};

export default VimeoLightbox;
