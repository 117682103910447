import React, { useCallback, useState, Fragment, useMemo, useRef, useEffect } from 'react';
import { useConfigProvider } from '@/lib/context';
import { logCommonViewAnonymous } from '@/lib/service';
import { EventSource } from '@/shared/constants/event';
import { useRouter } from 'next/router';
import Image from 'next/image';
import { AnalyticsGraphBar, PietraLogoIconFilled, Product, WarehouseStorage } from '@/components/Common/Icons';
import classNames from 'classnames';
import { imgix } from '@/shared/utils/utils';
import { useIntersection } from 'react-use';
import Link from 'next/link';
import Trustpilot from '../../Trustpilot';
import { STATIC_FILE_URL } from '@/shared/constants';
import 'animate.css';
import './index.less';
import HomeBanner from '@/components/Common/HomeBanner';
import ResponsiveContent from '@/components/Common/ResponsiveContent';

const HomePageIntro = () => {
  const router = useRouter();
  const tabsContentRef = useRef(null);
  const intersectionRef = useRef(null);
  const { isMobile } = useConfigProvider();
  const mainData = useMemo(
    () => [
      {
        order: 0,
        value: 'pietra-platform',
        label: <ResponsiveContent set={[['Platform', 860], ['Pietra platform']]} />,
        icon: <PietraLogoIconFilled />,
        body: {
          title: (
            <ResponsiveContent set={[["Don't waste time reinventing the wheel", 1024], ["Don't reinvent the wheel"]]} />
          ),
          description: (
            <ResponsiveContent
              set={[
                [
                  'Pietra gives operators access to popular solutions so they can focus on growth, not managing different providers.',
                  1024,
                ],
                ['Pietra gives your team access to proven solutions so you can focus on growing.'],
              ]}
            />
          ),
          content: {
            type: 'video',
            url: '/images/homepage-v3/chat-bubble-animation-og.webm',
            url2: '/images/homepage-v3/chat-bubble-animation-og.mp4',
          },
          backupImageURL: '/images/homepage-v3/chat-bubble-animation.jpg',
          link: {
            text: 'Create free account',
            href: '/sell-on-pietra/register',
          },
        },
        footer: {
          title: 'Works with',
          content: (
            <div className="image-list">
              <Image
                width={isMobile ? 52 : 82}
                height={isMobile ? 16 : 24}
                src="/svg/shopify-wordmark.svg"
                alt="Shopify Logo"
              />
              <Image
                width={isMobile ? 72 : 100}
                height={isMobile ? 11 : 18}
                src="/svg/tiktok-shop-wordmark.svg"
                alt="Tiktok Logo"
              />
              <Image
                width={isMobile ? 48 : 63}
                height={isMobile ? 12 : 20}
                src="/svg/amazon-wordmark.svg"
                alt="Amazon Logo"
              />
              <Image
                width={isMobile ? 37 : 50}
                height={isMobile ? 12 : 20}
                src="/svg/wix-wordmark.svg"
                alt="Wix Logo"
              />
              <Image
                width={isMobile ? 71 : 95}
                height={isMobile ? 12 : 20}
                src="/svg/woo-commerce-wordmark.svg"
                alt="Woo Commerce Logo"
              />
            </div>
          ),
        },
      },
      {
        order: 1,
        value: 'simplify-design-manufacturing',
        label: <ResponsiveContent set={[['Sourcing', 860], ['Simplify design & sourcing']]} />,
        icon: <Product />,
        body: {
          title: 'Make sourcing easier for your team',
          description:
            'Pietra makes getting quotes from suppliers a breeze. Create new product lines, make custom merch, and upgrade your packaging easier than ever before. ',
          content: {
            type: 'image',
            url: `${STATIC_FILE_URL}/73cae421c1d654059fcce503eb71ba5c.png`,
          },
          link: {
            text: 'Learn more',
            href: '/platform/sourcing-and-production/products',
          },
        },
      },
      {
        order: 2,
        value: 'simplify-order-fulfillment',
        label: <ResponsiveContent set={[['Fulfillment', 860], ['Simplify order fulfillment']]} />,
        icon: <WarehouseStorage />,
        body: {
          title: 'Reduce fulfillment costs up to 60%',
          description:
            'A modern 3PL designed to help you grow your DTC, wholesale, and retail business with $1.15/order unlimited picks.',
          content: {
            type: 'image',
            url: `${STATIC_FILE_URL}/c2c80af857d03d5c139c7a555a5c71b9.png`,
          },
          link: {
            text: 'Calculate my savings',
            href: '/platform/logistics-and-fulfillment',
          },
        },
      },
      {
        order: 3,
        value: 'simplify-marketing',
        label: <ResponsiveContent set={[['Marketing', 860], ['Simplify marketing']]} />,
        icon: <AnalyticsGraphBar />,
        body: {
          title: 'Pietra is your growth assistant',
          description:
            'Access the best software to 10x your email list, create an influencer affiliate program, and use AI assistants to help sell your products online. ',
          content: {
            type: 'image',
            url: `${STATIC_FILE_URL}/198d5ef15a12806d1f409c1d2ce94419.png`,
          },
          link: {
            text: 'Learn more',
            href: '/platform/sales-and-marketing',
          },
        },
      },
    ],
    [isMobile],
  );

  const [email, setEmail] = useState<string>('');
  const [activeKey, setActiveKey] = useState<string>(mainData[0].value);
  const [scrollLeft, setScrollLeft] = useState(0);
  const [isScrollAtRight, setIsScrollAtRight] = useState<boolean>(false);

  const intersection = useIntersection(intersectionRef, {
    root: null,
    rootMargin: '0px',
    threshold: 0.5,
  });

  const animateCSS = useCallback(
    (element: string, animation: string, prefix = 'animate__') =>
      // We create a Promise and return it
      new Promise((resolve) => {
        const animationName = `${prefix}${animation}`;
        const node = document.querySelector(element);
        node.setAttribute('style', `--animate-duration: 0.35s;`);
        node.classList.add(`${prefix}animated`, animationName);

        // When the animation ends, we clean the classes and resolve the Promise
        function handleAnimationEnd(event: any) {
          event.stopPropagation();
          node.classList.remove(`${prefix}animated`, animationName);
          resolve('Animation ended');
        }

        node.addEventListener('animationend', handleAnimationEnd, { once: true });
      }),
    [],
  );

  const handleNavigation = useCallback(
    (e) => {
      e.preventDefault();
      logCommonViewAnonymous(EventSource.A_B_TEST_EMAIL_COLLECTION_ON_HOMEPAGE_WITH_EMAIL_START, email, isMobile);

      router.push(
        '/sell-on-pietra/register?' +
          (email ? 'email=' + email + '&' : '') +
          'event_source=' +
          EventSource.A_B_TEST_EMAIL_COLLECTION_ON_HOMEPAGE_WITH_EMAIL_END.toLowerCase(),
      );
    },
    [email, isMobile, router],
  );

  const currentData = useMemo(() => mainData.find((item) => item.value === activeKey), [activeKey, mainData]);

  useEffect(() => {
    const tabsWrap = tabsContentRef.current;
    if (tabsWrap) {
      tabsWrap.scrollTo({
        left: 0,
        behavior: 'smooth',
      });
      tabsWrap.addEventListener('scroll', (e: any) => {
        setScrollLeft(e.currentTarget.scrollLeft);
        setIsScrollAtRight(e.currentTarget.scrollWidth - e.currentTarget.scrollLeft <= e.currentTarget.clientWidth);
      });
    }
    animateCSS('.content-left-wrap', 'fadeIn');
    animateCSS('.content-right-wrap', 'fadeIn');
  }, [animateCSS]);

  const [touchStart, setTouchStart] = useState(null);
  const [touchEnd, setTouchEnd] = useState(null);

  // the required distance between touchStart and touchEnd to be detected as a swipe
  const minSwipeDistance = 50;

  const onTouchStart = (e) => {
    setTouchEnd(null); // otherwise the swipe is fired even with usual touch events
    setTouchStart(e.targetTouches[0].clientX);
  };

  const onTouchMove = (e) => setTouchEnd(e.targetTouches[0].clientX);

  const onTouchEnd = () => {
    if (!touchStart || !touchEnd) return;
    const distance = touchStart - touchEnd;
    const isLeftSwipe = distance > minSwipeDistance;
    const isRightSwipe = distance < -minSwipeDistance;

    if (isLeftSwipe) {
      setActiveKey(mainData[currentData.order + 1].value);
      animateCSS('.content-left-wrap', 'fadeInRight');
      animateCSS('.content-right-wrap', 'fadeInRight');
    }

    if (isRightSwipe) {
      setActiveKey(mainData[currentData.order - 1].value);
      animateCSS('.content-left-wrap', 'fadeInLeft');
      animateCSS('.content-right-wrap', 'fadeInLeft');
    }
  };

  return (
    <div className="home-page-intro-wrap">
      <div className="home-page-intro-header-wrap">
        <HomeBanner />
        <div className="title">
          Simplify your {<ResponsiveContent set={[[<br key="home-page-intro-wrap-header-title-br" />, 576]]} />}
          e-commerce operations with Pietra
        </div>
        <div className="subtitle">
          <ResponsiveContent
            set={[
              ['Use turnkey solutions to move faster, save money, and build a more profitable business.', 1024],
              ['Move faster, save money, and build a more profitable business.'],
            ]}
          />
        </div>
      </div>
      <div className="home-page-intro-input-wrap">
        <input
          type="text"
          placeholder="Enter your email"
          onChange={(e) => {
            setEmail(e.target.value);
          }}
          onKeyDown={(e) => {
            if (e.key === 'Enter') {
              handleNavigation(e);
            }
          }}
        />
        <button onClick={handleNavigation}>Get started</button>
      </div>
      <div className="home-page-intro-main-wrap">
        <div className="tabs-wrap" data-scroll-left={scrollLeft} data-is-scroll-at-right={isScrollAtRight}>
          <div className="tabs-content-wrap" ref={tabsContentRef}>
            {mainData.map((item, index) => (
              <Fragment key={item.value}>
                <div
                  className={classNames('tab', {
                    active: activeKey === item.value,
                  })}
                  data-value={item.value}
                  onClick={() => {
                    setActiveKey(item.value);
                    if (item.order === currentData.order) return;
                    const fade = item.order > currentData.order ? 'fadeInRight' : 'fadeInLeft';
                    animateCSS('.content-left-wrap', fade);
                    animateCSS('.content-right-wrap', fade);
                  }}
                >
                  {item.icon}
                  {item.label && <span>{item.label}</span>}
                </div>
                {index === 0 && (
                  <ResponsiveContent
                    set={[
                      [null, 1024],
                      [
                        <div
                          className="vertical-divider"
                          key="home-page-intro-main-wrap-tabs-content-wrap-tab-vertical-divider"
                        />,
                      ],
                    ]}
                  />
                )}
              </Fragment>
            ))}
          </div>
        </div>
        <div
          className="home-page-intro-main-content-wrap"
          onTouchStart={onTouchStart}
          onTouchMove={onTouchMove}
          onTouchEnd={onTouchEnd}
        >
          <div className="content-left-wrap">
            <div className="title">{currentData?.body.title}</div>
            <div className="description">{currentData?.body.description}</div>
            {currentData?.footer && (
              <div className="footer">
                <div className="title">{currentData?.footer.title}</div>
                <div className="content">{currentData?.footer.content}</div>
              </div>
            )}
            {currentData?.body?.link && (
              <Link className="link" href={currentData?.body?.link.href}>
                <span>{currentData?.body?.link.text}</span>
              </Link>
            )}
          </div>
          <div className="content-right-wrap" ref={intersectionRef}>
            {currentData?.body.content.type === 'video' ? (
              <video
                autoPlay
                playsInline
                muted
                loop={true}
                preload="metadata"
                poster={imgix(currentData?.body?.backupImageURL, { q: 40, w: 716, dpr: 2 })}
              >
                <source src={currentData?.body.content.url} type="video/webm" />
                <source src={currentData?.body.content.url2} type="video/mp4" />
                <img
                  src={imgix(currentData?.body?.backupImageURL, { q: 80, h: 404 })}
                  srcSet={[
                    imgix(currentData?.body?.backupImageURL, { q: 80, h: 404, dpr: 1 }) + ' 1x',
                    imgix(currentData?.body?.backupImageURL, { q: 40, h: 404, dpr: 2 }) + ' 2x',
                    imgix(currentData?.body?.backupImageURL, { q: 20, h: 404, dpr: 3 }) + ' 3x',
                  ].join(', ')}
                  loading="eager"
                  fetchPriority="high"
                  alt="Content Image"
                  title="Your video is not loaded"
                />
              </video>
            ) : (
              <img
                src={imgix(currentData?.body.content.url, { q: 80, h: 404 })}
                srcSet={[
                  imgix(currentData?.body.content.url, { q: 80, h: 404, dpr: 1 }) + ' 1x',
                  imgix(currentData?.body.content.url, { q: 40, h: 404, dpr: 2 }) + ' 2x',
                  imgix(currentData?.body.content.url, { q: 20, h: 404, dpr: 3 }) + ' 3x',
                ].join(', ')}
                loading="eager"
                fetchPriority="high"
                alt="Content Image"
              />
            )}
          </div>
        </div>
      </div>
      <Trustpilot className="home-page-intro-rating-wrap" />
    </div>
  );
};

export default HomePageIntro;
